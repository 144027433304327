<!-- 父母信息 -->
<script setup>
import { useAppStore } from '@store/appStore';
import { defineProps, inject, watch } from 'vue';
import { TRADE_TYPE} from '@/constants'
const appStore = useAppStore();
// 由 nftMarket/Detail provide 下来的属性
// const cattleAttr = inject('cattleAttr');

// 由 planetStore/planetInfo provide的属性
const planetAttr = inject('planetAttr');

// 由 nftMarket/Detail 分发 下来的属性
const props = defineProps({
  attrs: {
    type: Object,
    default: []
  },
  // 要展示的类型
  showType: {
    type: String
  }
});
</script>

<template>
  <!-- 牛牛属性 -->
  <template v-if="props.showType === 'calf' || props.showType === 'blindBox'">
    <div class="basic-attr-wrap" v-for="(item, index) in props.attrs" :key="index">
      <!-- 属性标题 -->
      <div class="sub-title">{{ $t(item.class) }}</div>
      <!-- 基础属性项 -->
      <div class="attr-item">
        <div class="item" v-for="(it, ind) in item.info" :key="ind">
          <!-- 属性名称 -->
          <div class="name" v-show="appStore.curDevice == 'pc'">{{ $t(it.name) }}</div>
          <!-- 属性数值 -->
          <div class="block">
            <div class="block-item">
              <i class="iconfont" :class="`icon-${it.eName}`"></i>
            </div>
            <div class="val-wrap">
              <div class="name" v-show="appStore.curDevice !== 'pc'">{{ $t(it.name) }}</div>
              <div class="val">{{ $t(it.value) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <!-- 星球属性 -->
  <template v-else-if="props.showType === 'planet'">
    <div class="label-wrap">
      <div class="title">{{ $t('detail.21') }}</div>
      <span>{{ `${planetAttr.population}/${planetAttr.populationLimit}` }}</span>
    </div>

    <div class="label-wrap">
      <div class="title">{{ $t('detail.22') }}</div>
      <span>{{ `${planetAttr.tax}%` }}</span>
    </div>

    <div class="label-wrap">
      <div class="title">{{ $t('detail.49') }}</div>
      <span>{{ `${planetAttr.totalTax} ${TRADE_TYPE[1]}` }}</span>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.basic-attr-wrap {
  margin-top: 0.16rem;
  width: 100%;
  @media (max-width: $pad) {
    // width: 45%;
  }

  .sub-title {
    font-size: 0.24rem;
    // font-family: 'pingfang-semibold';
    // @include -media($pc, font-family, 'pingfang-semibold');
    font-weight: 400;
    line-height: 0.28rem;
  }

  .attr-item {
    @include flexPos(flex-start, center);
    margin-top: 0.1rem;
    .item {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 0.2rem;
        // font-family: 'pingfang-regular';
        // @include -media($pc, font-family, 'pingfang-regular');
        font-weight: 400;
      }
    }
  }

  .block {
    @include flexPos(flex-start, center);
    margin-top: 0.1rem;
    @include -width(2.27rem, 2.27rem, 2.07rem);

    .block-item {
      @include -width(0.52rem, 0.52rem, 0.46rem);
      @include -height(0.52rem, 0.52rem, 0.46rem);
      background-color: #553cac;
      border-radius: 6px;
      @include flexPos(center);
    }

    .iconfont {
      @include -font-size(0.36rem, 0.36rem, 0.32rem);
    }

    .val-wrap {
      @include flexPos(flex-start, flex-start);
      flex-direction: column;
      padding-left: 0.12rem;
      @include -media($pc, padding-left, 0.1rem);
      .val {
        @include -font-size(0.24rem, 0.24rem, 0.24rem);
        // font-family: 'pingfang-regular';
        // @include -media($pc, font-family, 'pingfang-regular');
        font-weight: 200;
        // padding-left: 0.08rem;
      }
    }
  }
}

.label-wrap {
  @include flexPos(flex-start, center);
  font-size: 0.22rem;
  margin-top: 0.2rem;
  .title {
    font-size: 0.26rem;
    margin-right: 0.2rem;
  }
  span {
    @include flexPos(center);
  }
}
</style>
