<!-- 市场详情页面通用组件 -->
<script setup>
import { ref, inject, onMounted, defineProps, computed, onBeforeUnmount } from 'vue';
import { useAppStore } from '@store/appStore';
import { useRoute, useRouter } from 'vue-router';
import { handleCopy } from '@/utils/tools.js';
import AttrsComp from './components/AttrsComp.vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useI18n } from 'vue-i18n';
import { plusXing, getTradeTypeCoin, getFullNum } from '@/utils/tools';
import useCattleMintStore from '@contractStore/cattleMintStore';

const appStore = useAppStore();
const $tx = useI18n();
const cattleMintStore = useCattleMintStore();

const props = defineProps({
  // 数据列表
  dataList: {
    type: Array,
    default: () => [],
  },

  // 要展示的类型
  showCate: {
    type: String,
  },
});

// 返回交易市场
const $router = useRouter();
const $route = useRoute();

function backtoMarket() {
  // $router.push({ name: 'nftMarket' })
  if (!$route.query.from) {
    $router.back();
  } else {
    $router.push({
      name: $route.query.from,
      query: {
        type: $route.query.type,
      },
    });
  }
}

onMounted(() => {
  handleCopy();
});

const hasParentsId = inject('hasParents'); // 牛牛是否存在父母
const cattleAttr = inject('cattleAttr'); // 牛牛属性
const fatherAttr = inject('fatherAttr'); // 公牛属性
const motherAttr = inject('motherAttr'); // 母牛属性
const dateTime = inject('cattleDeadTime'); // 普通牛死亡时间
// const planetAttr = inject('planetAttr'); // 牛牛属性
const tokenId = inject('tokenId'); // 牛牛tokenid
const cardName = inject('cardName'); // 卡牌名称
const ownerAddr = inject('ownerAddr'); // 卡牌名称
const cardImg = inject('cardImg'); // 卡牌图片
const contractAddr = inject('contractAddr'); // 合约地址
const priceObj = inject('priceObj'); // 价格对象：price、doller、tradeType
const desc = inject('desc'); // 描述信息

const countDown = ref(null);
const checkTimer = ref(null);
dayjs.extend(duration);
const cattleDeadTime = computed(() => {
  if (!dateTime.value) return null;
  const days = dayjs(dateTime.value).diff(dayjs(), 'day');
  if (days < 1) {
    setCountDown();
    return countDown.value;
  } else {
    return `${days} ${$tx.t('blindBox.3')}`;
  }
});
/**
 * 小於一天的時候展示倒計時
 */
function setCountDown() {
  checkTimer.value = setInterval(() => {
    const now = new Date().getTime(); // 当前时间戳

    const diffTime = dayjs.duration(dateTime.value - now);
    const hours = diffTime.hours(); //小时
    const minutes = diffTime.minutes(); //分钟
    const seconds = diffTime.seconds(); //秒
    countDown.value = `${_toTwo(hours)}:${_toTwo(minutes)}:${_toTwo(seconds)}`;
  }, 1000);
}

/**
 * 不足两位数的前面补0
 */
function _toTwo(num) {
  return num.toString().length === 2 ? num : '0' + num;
}

onBeforeUnmount(() => {
  clearInterval(checkTimer.value);
});

// 消耗的币
// const coin = getTradeTypeCoin(priceObj.tradeType);

// 判断有没有父母
const hasParent = computed(() => {
  if ($route.query.type == 'planet') {
    return false;
  }

  if (hasParentsId.value) {
    return true;
  } else {
    return false;
  }
});
</script>

<template>
  <div class="detail-wrap">
    <div class="detail-container">
      <div class="back" v-if="appStore.curDevice === 'pc'">
        <div @click="backtoMarket">
          <i class="iconfont icon-simple-arrow"></i>
          <span style="font-size: 0.34rem">{{ $t('dialog.3') }}</span>
        </div>
      </div>

      <div class="card-container">
        <!-- 牛牛卡牌 -->
        <div class="img-wrap">
          <img :src="cardImg" alt="" class="card-img bg-img" />

          <div class="contract-container" v-show="appStore.curDevice == 'pc'">
            <!-- 合约地址 -->
            <div class="contract-content">
              <div>{{ $t('detail.18') }}</div>
              <div>
                <span>{{ plusXing(contractAddr, 8, 8) }}</span>
                <i :data-clipboard-text="contractAddr" class="iconfont icon-cpy cpy-btn"></i>
              </div>
            </div>

            <!-- token id -->
            <div class="token-id">
              <div style="margin-top: 0.1rem">{{ $t('detail.19') }}</div>
              <div>
                <span>{{ tokenId }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="info-wrap">
          <header>
            <!-- 牛牛 -->
            <template v-if="props.showCate === 'calf'">
              <span class="title">{{ cardName }}</span>
              <i :class="['iconfont', cattleAttr[0]?.gender === 1 ? 'icon-nan' : 'icon-nv']"></i>
            </template>

            <!-- 盲盒 -->
            <template v-else-if="props.showCate === 'blindBox'"> {{ $t('card.1') }} </template>

            <!-- 星球 -->
            <template v-else-if="props.showCate === 'planet'">{{ $t('card.12') }} </template>
          </header>

          <div class="holder">
            <div class="title">{{ $t('detail.13') }}</div>
            <div class="addr">
              <span>{{ ownerAddr }}</span>
              <i :data-clipboard-text="ownerAddr" class="cpy-btn iconfont icon-cpy"></i>
            </div>
          </div>

          <div class="dead-time-wrap" v-show="dateTime && props.showCate === 'calf'">
            <div class="label">{{ $t('detail.61') }}</div>
            <div>{{ cattleDeadTime }}</div>
          </div>

          <!-- 牛牛属性 -->
          <div class="arrt-wrap">
            <AttrsComp
              :showType="props.showCate"
              :attrs="props.showCate == 'blindBox' ? null : cattleAttr"
            />
          </div>

          <!-- 描述 -->
          <div class="desc-container">
            <div>{{ $t('detail.20') }}</div>
            <div class="label">{{ desc }}</div>
            <div v-show="props.showCate == 'blindBox'" class="tip">{{ $t('detail.62') }}</div>
          </div>
          <!-- button -->
          <div class="footer-container" v-show="appStore.curDevice == 'pc'">
            <div class="price-detail">
              <div class="title">{{ $t('detail.31') }}</div>
              <div class="price-container">
                <div
                  :class="['market-coins', `icons-${getTradeTypeCoin(priceObj?.tradeType)}`]"
                ></div>
                <div class="price-content">
                  {{ getFullNum(Number(priceObj.price).toFixed(3)) }}
                </div>
                <div class="coin-name">
                  {{ getTradeTypeCoin(priceObj?.tradeType)?.toUpperCase?.() }}
                </div>
              </div>
              <!-- 美元 -->
              <!-- <div class="doller">≈${{ priceObj.doller }}</div> -->
            </div>

            <div class="btns" v-show="appStore.curDevice == 'pc'">
              <button v-if="appStore.curDevice !== 'pc'" class="back-btn" @click="backtoMarket">
                {{ $t('dialog.3') }}
              </button>
              <slot name="btn"></slot>
            </div>
          </div>
        </div>
      </div>

      <footer v-show="appStore.curDevice !== 'pc'">
        <div class="contract-container">
          <!-- 合约地址 -->
          <div class="contract-content">
            <div>{{ $t('detail.18') }}</div>
            <div>
              <span>{{ plusXing(contractAddr, 8, 8) }}</span>
              <i :data-clipboard-text="contractAddr" class="iconfont icon-cpy cpy-btn"></i>
            </div>
          </div>

          <!-- token id -->
          <div class="token-id">
            <div style="margin-top: 0.1rem">{{ $t('detail.19') }}</div>
            <div>
              <span>{{ tokenId }}</span>
            </div>
          </div>
        </div>

        <div>
          <div class="footer-detail">
            <div class="title">{{ $t('detail.31') }}</div>
            <div class="mob-price-container">
              <div
                :class="['market-coins', `icons-${getTradeTypeCoin(priceObj?.tradeType)}`]"
              ></div>
              <div class="price-content">
                {{ getFullNum(Number(priceObj.price).toFixed(3)) }}
              </div>
              <div class="coin-name">
                {{ getTradeTypeCoin(priceObj?.tradeType)?.toUpperCase?.() }}
              </div>
              <!-- 美元 -->
              <!-- <div class="doller">≈${{ priceObj.doller }}</div> -->
            </div>
          </div>

          <div class="btns">
            <button v-if="appStore.curDevice !== 'pc'" class="back-btn" @click="backtoMarket">
              {{ $t('dialog.3') }}
            </button>
            <slot name="btn"></slot>
          </div>
        </div>
      </footer>

      <!-- 父母属性 -->
      <div class="parent-attrs-container" v-if="hasParent">
        <div class="head">{{ $t('detail.35') }}</div>
        <template v-if="fatherAttr !== null">
          <div class="father-attr">
            <div class="title">{{ $t('detail.36') }}</div>
            <AttrsComp :showType="props.showCate" :attrs="fatherAttr" />
          </div>
        </template>

        <template v-if="motherAttr !== null">
          <div class="mother-attr">
            <div class="title">{{ $t('detail.37') }}</div>
            <AttrsComp :showType="props.showCate" :attrs="motherAttr" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './index.scss';

.detail-wrap {
  color: #fff;
  width: 100%;
  min-height: calc(100vh - $pcTopBarHeight);
  padding: 0 0 1.16rem;
  border-top: solid 0.1px transparent; // 为了解决margin塌陷

  @include nftImgBg;

  @media (max-width: $pad) {
    min-height: calc(100vh - $mobTopBarHeight);
    padding: 0 0.4rem 1.16rem;
  }

  .detail-container {
    margin: 0 auto;
    @media (min-width: $pad) {
      width: 12.8rem;
    }
  }

  .back {
    margin: 0.5rem 0 0.28rem;
    @include flexPos(flex-start, center);
    font-size: 0.34rem;
    cursor: pointer;

    > div {
      @include flexPos(flex-start, center);
    }

    .iconfont {
      margin-right: 0.1rem;
      font-size: 0.24rem;
    }
  }
}

// 卡牌信息
.card-container {
  display: flex;
  width: 100%;

  @media (max-width: $pad) {
    @include flexPos(center, flex-start);
    flex-direction: column;
  }

  @media (max-width: $phone) {
    @include flexPos(center);
  }

  .bg-img {
    background-image: linear-gradient(to bottom, transparent, #5a25b1 100%);
    border: 1px solid rgba($color: #fff, $alpha: 0.5);
    border-radius: 0.1rem;
  }

  .img-wrap {
    @include flexPos(flex-start, center);
    flex-direction: column;
    @include -width(3.18rem, 4.85rem, 4.85rem);
    @include -media($pc, margin-top, 0);
    margin: 0.31rem 0;
    @media (min-width: $pad) {
      margin-right: 0.72rem;
    }
    .card-img {
      @include -width(3.18rem, 4.85rem, 4.85rem);
    }
  }

  .info-wrap {
    @include flexPos(flex-start, flex-start);
    flex-direction: column;
    border-radius: 0.06rem;
    width: 100%;
    line-height: 0.28rem;

    @media (min-width: $phone) and (max-width: $pad) {
      :deep(.basic-attr-wrap) {
        width: 45%;
      }
    }

    header {
      margin-right: 0.17rem;
      @include flexPos(space-around);

      .iconfont {
        font-size: 0.26rem;
        margin-left: 0.15rem;
      }

      @media (max-width: $phone) {
        width: 100%;
        font-size: 0.6rem;
        justify-content: center;

        .iconfont {
          font-size: 0.45rem;
        }
      }
    }

    .arrt-wrap {
      border-top: solid 1.5px #282644;
      @include -media($pc, border-top, none);
    }

    .holder {
      padding-top: 0.2rem;
      font-size: 0.26rem;
      line-height: 0.28rem;
      @media (max-width: $pad) {
        padding-bottom: 0.2rem;
        margin-top: 0.32rem;
        width: 100%;
      }
      .title {
        font-size: 0.26rem;
      }
      .addr {
        font-size: 0.24rem;
      }
    }

    .planet-title {
      padding-top: 0.2rem;
      font-size: 0.22rem;
    }
    span {
      @include -font-size(0.24rem, 0.24rem, 0.2rem);
    }
    .title {
      @include -font-size(0.45rem, 0.45rem, 0.36rem);
    }
  }

  .dead-time-wrap {
    font-size: 0.22rem;
    // font-family: 'pingfang-regular';
    // @include -media($pc, font-family, 'pingfang-regular');
    font-weight: 300;
    margin-top: 0.24rem;
    width: 100%;
    line-height: 0.32rem;
    .label {
      font-size: 0.26rem;
      // font-family: 'pingfang-semibold';
      // @include -media($pc, font-family, 'pingfang-semibold');
      font-weight: 500;
      margin-bottom: 0.1rem;
    }

    @media (max-width: $pad) {
      padding: 0.32rem 0;
      border-bottom: solid 1.5px #282644;
    }
  }

  .desc-container {
    @include -font-size(0.28rem, 0.28rem, 0.26rem);
    // @include -media($pc, font-family, 'pingfang-regular');
    line-height: 0.35rem;
    margin-top: 0.24rem;
    width: 100%;
    .label {
      @include -font-size(0.26rem, 0.26rem, 0.24rem);
      // @include -media($pc, font-family, 'pingfang-regular');
      font-weight: 200;
    }
    .tip{
      margin-top: 0.24rem;
    }

    @media (max-width: $pad) {
      padding: 0.26rem 0;
      border-bottom: solid 1.5px #282644;
      border-top: solid 1.5px #282644;
      margin-bottom: 0.32rem;
    }
  }
}
footer {
  width: 100%;
  @media (min-width: $pad) {
    @include flexPos(flex-start, center);
  }
}

.footer-detail {
  @media (max-width: $pad) {
    @include flexPos(space-between);
    border-top: solid 1.5px #282644;
    padding-top: 0.28rem;
    margin-top: 0.36rem;
  }
  .title {
    font-size: 0.3rem;
  }

  .price-detail-content {
    @include flexPos(space-between);

    .icon-coin {
      font-size: 0.35rem;
    }
  }

  .mob-price-container {
    @include flexPos(center);
    font-size: 0.48rem;
    line-height: 0.56rem;
    .price-content {
      max-width: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 0.04rem 0 0.06rem;
    }
    .coin-name {
      margin: 0 0.04rem 0 0.06rem;
    }
    .market-coins {
      width: 0.48rem;
      height: 0.48rem;
    }
    .doller {
      text-align: left;
      font-size: 0.28rem;
      line-height: 0.33rem;
    }
  }
}

.btns {
  @include flexPos(center);
  @media (max-width: $pad) {
    @include flexPos(space-around, center);
    margin-top: 0.72rem;
  }

  :deep(button) {
    width: 3.14rem;
    height: 0.81rem;
    background: #5b3bb7;
    border-radius: 100px;
    font-size: 0.36rem;
    // margin-left: 0.68rem;
    cursor: pointer;

    @media (max-width: $pad) {
      width: 2.96rem;
      height: 0.7rem;
      margin: 0;
      display: flex;
      border-radius: 0.06rem;
      justify-content: center;
      align-items: center;
      font-size: 0.28rem;
    }
  }
}

// 合约地址信息
.contract-container {
  @include -font-size(0.26rem, 0.26rem, 0.24rem);
  min-width: 4.58rem;
  line-height: 0.28rem;
  @include -margin-top(0, 0, 0.3rem);

  > div {
    @media (max-width: $pad) {
      @include flexPos(space-between);
    }

    > div {
      @include flexPos(flex-start);
    }

    .iconfont {
      @include -font-size(0.26rem, 0.26rem, 0.24rem);
      margin-left: 0.06rem;
      cursor: pointer;
    }
  }
}

.footer-container {
  @media (min-width: $pad) {
    display: flex;
  }
  margin-top: 0.8rem;
  .price-detail {
    .title {
      font-size: 0.3rem;
      line-height: 0.35rem;
    }
    @media (max-width: $pad) {
      @include flexPos(space-between);
      border-top: solid 1.5px #282644;
      padding-top: 0.28rem;
      margin-top: 0.36rem;
    }

    .price-detail-content {
      @include flexPos(space-between);

      .icon-coin {
        font-size: 0.35rem;
      }
    }

    .price-container {
      @include flexPos(center);
      margin-right: 0.45rem;
      font-size: 0.4rem;
      line-height: 0.46rem;
      @include -media($phone, font-size, 0.24rem);
      .price-content {
        margin: 0 0.04rem 0 0.06rem;
      }
      .coin-name {
      }
    }
    .market-coins {
      width: 0.3rem;
      height: 0.3rem;
    }
    .doller {
      margin-left: 0.45rem;
      text-align: left;
      font-size: 0.24rem;
    }
  }

  .btns {
    @include flexPos(center);
    @media (max-width: $pad) {
      @include flexPos(space-around, center);
      margin-top: 0.72rem;
    }

    :deep(button) {
      width: 3.14rem;
      height: 0.81rem;
      background: #5b3bb7;
      border-radius: 100px;
      font-size: 0.36rem;
      // margin-left: 0.68rem;
      cursor: pointer;

      @media (max-width: $pad) {
        width: 2.96rem;
        height: 0.7rem;
        margin: 0;
        display: flex;
        border-radius: 0.06rem;
        justify-content: center;
        align-items: center;
        font-size: 0.28rem;
      }
    }
  }
}

// 父母属性
.parent-attrs-container {
  width: 5.14rem;
  margin-top: 0.7rem;
  .head {
    font-size: 0.5rem;
  }

  .title {
    font-size: 0.4rem;
    margin: 0.24rem 0;
  }

  .mother-attr {
    margin-top: 0.67rem;
  }
}
</style>
