<!-- 下架订单弹窗 -->
<script setup>
import { defineProps, defineEmits, inject, watchEffect, ref } from 'vue';
import { getTradeTypeCoin } from '@/utils/tools';

const props = defineProps({
  isApprove: {
    type: Boolean,
    default: false
  }
});

const $emits = defineEmits(['confirm', 'cancel']);

const priceObj = inject('priceObj'); // 价格对象：price、doller
const coin = getTradeTypeCoin(priceObj.tradeType);
</script>

<template>
  <div class="order-pop-wrap">
    <header>{{ $t('panel.31') }}</header>

    <div class="more-crypto">
      <span style="margin-left: 0.1rem">{{ $t('msg.23') }}</span>
    </div>

    <div class="btns">
      <button @click="$emits('confirm')">
        {{ $t('dialog.1') }}
      </button>
      <button @click="$emits('cancel')">{{ $t('dialog.6') }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.order-pop-wrap {
  width: 6.48rem;
  // height: 4.17rem;
  background-image: linear-gradient(to bottom, transparent, #402866 100%);
  padding: 0.36rem 0.88rem 0.6rem;
  border: solid 0.01rem rgba(#fff, 0.5);
  border-radius: 0.1rem;
  backdrop-filter: blur(20px);
  color: #fff;
}

header {
  width: 100%;
  font-size: 0.4rem;
  line-height: 0.46rem;
  text-align: center;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid #f5efff;
}

.tips {
  font-size: 0.24rem;
}

.price-container {
  margin: 0.52rem auto 0.88rem;
  @include flexPos(center);
  > div {
    margin-left: 0.05rem;
  }

  .icon-coin {
    font-size: 0.38rem;
  }

  .price {
    font-size: 0.4rem;
  }

  .doller {
    font-size: 0.2rem;
  }
}

.ava-bnb {
  font-size: 0.18rem;
  @include flexPos(center);
}

.more-crypto {
  @include flexPos(center);
  font-size: 0.24rem;
  margin: 0.52rem auto 0.88rem;
  color: rgba(255, 255, 255, 0.5);
}

.btns {
  @include flexPos(space-around);

  button {
    width: 1.57rem;
    height: 0.49rem;
    border-radius: 0.25rem;
    font-size: 0.22rem;
    font-weight: 500;
    background-color: transparent;
    border: solid 1px #fff;
    cursor: pointer;
  }
}
</style>
